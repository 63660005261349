export const FREE_PLAN = "free";
export const PAID_PLAN = "paid";
export const BASIC_PLAN = "basic";
export const PRO_1_PLAN = "pro_1";
export const PRO_2_PLAN = "pro_2";
export const PREMIUM_PLAN = "premium";
export const CUSTOM_PLAN = "custom";
export const PAID_PLANS = [
  PAID_PLAN,
  BASIC_PLAN,
  PRO_1_PLAN,
  PRO_2_PLAN,
  PREMIUM_PLAN,
  CUSTOM_PLAN
];

export const UPGRADE_PLANS = [
  {
    title: "Premium",
    number_of_emails: "1 million",
    IP_amount: 4,
    cost: "345,35",
    icon: "plantPremiumPlan"
  },
  {
    title: "Major pro",
    number_of_emails: "500.000",
    IP_amount: 2,
    cost: "229,85",
    icon: "plantMajorProPlan"
  },
  {
    title: "Pro",
    number_of_emails: "250.000",
    IP_amount: 2,
    cost: "137,45",
    icon: "plantProPlan"
  },
  {
    title: "Basic",
    number_of_emails: "100.000",
    IP_amount: 1,
    cost: "68,15",
    icon: "plantBasicPlan"
  }
];

export const CUSTOM_PLANS = [
  { label: "1-5 million emails /month", value: "1-5 million emails /month" },
  { label: "5-10 million emails /month", value: "5-10 million emails /month" },
  { label: "10 million + emails /month", value: "10 million + emails /month" }
];
